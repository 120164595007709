
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Table } from "@/utils/decorator";
import { emptyToLine, splitThousands } from "@/utils/common";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const message = namespace("message");

const finance = namespace("finance");

@Component({})
@Table("loadListData")
export default class Customer extends Vue {
  @finance.Action getBillList;
  @message.Action getConsumerLogger;

  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  searchForm = {
    customerName: "",
    customerMobile: "",
    beginSendTime: "",
    endSendTime: ""
  };
  consumptionList: any[] = [];
  searchList: any[] = [
    {
      label: t("finance.guest-names"),
      type: "input",
      value: "",
      prop: "customerName"
    },
    {
      label: t("v210831.send-phone"),
      type: "input",
      value: "",
      prop: "customerMobile"
    },
    {
      label: t("v210831.date-sent"),
      type: "daterange",
      value: "",
      prop: "sendTime"
    }
  ];
  created() {
    this.init();
  }
  //多行文本省略
  filters(value) {
    if (!value) return "";
    if (value.length > 30) {
      return value.slice(0, 30) + "...";
    }
    return value;
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "sendTime") {
          this.searchForm.beginSendTime = item.value ? item.value[0] : "";
          this.searchForm.endSendTime = item.value ? item.value[1] : "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getConsumerLogger({
      orderItems: this.mix_sortParams,
      ...this.searchForm,
      pageSize: this.mix_pageSize,
      pageNum: this.mix_pageNum
    }).then(data => {
      this.consumptionList = data.data.list;
      return data;
    });
  }
  init() {
    this.loadListData();
  }
}
